import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizationStoreProvider } from '@modeso/modeso-lib-localization-fe';
import { filter } from 'rxjs';
import { environment } from '../environments/environment';
import { getLanguage } from './domain/utils/language';
import { getFromStorage, saveToStorage } from './domain/utils/storage';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private readonly localizationStoreProvider: LocalizationStoreProvider,
        private readonly router: Router,
    ) {}

    ngOnInit(): void {
        this.localizationStoreProvider.loadLocalizations();
        this.setDebuggingMode();

        this.changeUrlLanguage();
        this.updateIndexHtmlFile();
    }

    private changeUrlLanguage(): void {
        // Wait for the router to finish navigating and get the final URL
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            // Extract browser's preferred language
            const browserLanguage = getLanguage();

            // Get the current language from localStorage
            const currentLanguage = getFromStorage('CURRENT_LANGUAGE');
            if (currentLanguage !== browserLanguage) {
                // Set the current language based on the header language
                saveToStorage('CURRENT_LANGUAGE', browserLanguage);
            }

            // Get the current URL
            const currentUrl = this.router.url;

            const urlSegments = currentUrl.split('/');
            const LANGUAGE_INDEX = 1;

            const ASSETS_NAME = 'assets';

            // Check if the language segment in the URL is the same as the header language
            if (urlSegments[LANGUAGE_INDEX] !== browserLanguage && urlSegments[LANGUAGE_INDEX] !== ASSETS_NAME) {
                // Update the URL based on the language
                urlSegments[LANGUAGE_INDEX] = browserLanguage; // Replace the language segment
                const newUrl = urlSegments.join('/');

                // Navigate to the new URL without triggering another NavigationEnd event
                void this.router.navigateByUrl(newUrl);
            }
        });
    }

    /**
     * Set the debugging mode in local storage
     */
    private setDebuggingMode(): void {
        saveToStorage('DEBUG', environment.enableDebugging);
    }

    private updateIndexHtmlFile(): void {
        try {
            const script = document.createElement('script');
            script.defer = true;
            script.src = 'https://plausible.io/js/script.js';
            script.setAttribute('data-domain', environment.dataDomain);
            document.head.appendChild(script);
        } catch (error) {
            console.error('Error appending plausible', error);
        }
    }
}
